.root {
	display: flex;
	margin-top: 64px;
	margin-left: 240px;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.content {
	padding: 24px;
}
@media only screen and (min-width: 1500px) {
	.content {
		width: 1200px;
	}
}

@media only screen and (min-width: 1700px) {
	.content {
		width: 1400px;
	}
}

@media only screen and (min-width: 1900px) {
	.content {
		width: 1600px;
	}
}

.cmsAppBar {
	padding-left: 240px;
	background: #222c32;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
	color: #fff;
}

.cmsAppBarMenuButton {
	display: none;
}

.cmsAppBarTitle {
	font-weight: bold;
	flex-grow: 1;
	padding-left: 16px;
}

.cmsAppBarFancyButton {
	background: "#f16355";
	color: #fff;
	border-radius: "25px";
}

.drawerPaper {
	width: 240px;
	background-color: #263238;
	color: #f0f8ff;
}

.card {
	box-shadow: 0 2px 4px rgba(38, 50, 56, 0.15);
	margin-bottom: 16px;
	border-radius: 0;
	overflow: visible;
}

.infoCard {
	background-color: aliceblue;
}

.textField {
	width: 100%;
}

.photoGrid {
	/* Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. */

	transform: translateZ(0);
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;

	gap: 1px;
	width: 100%;
}

.photoGridTile {
	height: 222px;
	width: auto;
	background-position: center;
	position: relative;
	background-size: cover;
	cursor: move;
	/* transition: 0.3s; */
	/* transition-property: filter; */
	font-weight: 500;
	background-color: #fcfcfc;
	user-select: none;
}
.photoGridTile:nth-child(n + 5):nth-child(-n + 8) {
	margin-bottom: 50px;
}

.photosTopTitleBar {
	width: 100%;
	padding: 0.5em 0.5em 1.5em 0.5em;
	display: flex;
	justify-content: end;
	align-items: center;
	gap: 2px;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
	color: #fff;
}

.photosBottomTitleBar {
	width: 100%;
	padding: 1.5em 0.5em 0.5em 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: 0;
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
	color: #fff;
}

.photosBottomTitleBar #dragDropHelpText {
	display: none;
}

.photosBottomTitleBar:hover #indexText {
	display: none;
}

.photosBottomTitleBar:hover #dragDropHelpText {
	display: inline-block;
}

.deleteIcon {
	color: #fff;
}

.categoriesSection {
	margin-bottom: 16px;
}

.categoriesSection:last-child {
	margin-bottom: 0;
}

.categoriesSection p {
	margin-bottom: 8px;
}

.categoriesSection .MuiChip-root {
	margin: 0px 4px 4px 0px;
	text-transform: capitalize;
}

.snack_success {
	background-color: #43a047;
}

.snack_error {
	background-color: #d32f2f;
}

.snack_info {
	background-color: #90caf9;
}

.snack_warning {
	background-color: #ffa000;
}

.snack_icon {
	font-size: 20;
}

.snack_message {
	display: flex;
	align-items: center;
}

.snack_iconVariant {
	opacity: 0.9;
	/* margin-right: theme.spacing(1) */
	margin-right: 10px;
}

.unselected {
	filter: grayscale(100%);
}

.flag-lang {
	cursor: pointer;
	padding: 0.15em;
	margin: 0 0.25em 0 0.25em;
	height: 30px;
}

.flag-lang.flag-selected {
	filter: grayscale(0%);
}

.flag-lang:hover {
	filter: grayscale(0%);
}

/* This terrible RTE editor styling is necessary since we need to override an imported component*/

.cruncho-rte-toolbar {
	padding: 18.5px 14px;
	background: #f9f9f9 !important;
	border-color: rgba(0, 0, 0, 0.23) !important;
	border-radius: 4px 4px 0 0 !important;

	margin-bottom: 0 !important;

	border-bottom-color: rgba(0, 0, 0, 0.11) !important;
}
.cruncho-rte-wrapper:hover > .cruncho-rte-editor {
	border-color: rgba(0, 0, 0, 0.87) !important;
}

.cruncho-rte-wrapper:hover > .cruncho-rte-toolbar {
	border-top-color: rgba(0, 0, 0, 0.87) !important;
	border-left-color: rgba(0, 0, 0, 0.87) !important;
	border-right-color: rgba(0, 0, 0, 0.87) !important;
}

.cruncho-rte-wrapper:focus-within > .cruncho-rte-editor {
	border-color: #3f51b5 !important;
	border-width: 2px;
	padding: 0 13px;
}

.cruncho-rte-wrapper:focus-within > .cruncho-rte-toolbar {
	border-top-color: #3f51b5 !important;
	border-left-color: #3f51b5 !important;
	border-right-color: #3f51b5 !important;
	border-top-width: 2px;
	border-left-width: 2px;
	border-right-width: 2px;
	padding: 5px 4px 0;
}

.cruncho-rte-editor {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	min-height: 160px;
	max-height: 200px;
	font-size: 16px;
	padding: 0 14px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 0 0 4px 4px;
	border-top: 0;
	cursor: text;
}

.dzu-input {
	display: none;
}

.sub-button {
	background-color: #3f51b5;
	color: #fff;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
		0px 1px 5px 0px rgb(0 0 0 / 12%) s;
}

.curationPhotoCircularProgress {
	position: absolute;
	top: 40%;
	left: 40%;
	z-index: 2;
}

.curationPhotoBackgroundProgress {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: gray;
	opacity: 50%;
	z-index: 1;
	top: 0;
	left: 0;
}
